import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Content, { HTMLContent } from '../components/Content';
import Seo from '../components/seo';
import PageHeader from '../components/PageHeader';

const ProfilePageTemplate = ({ title, content, contentComponent, image }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <PageHeader title={title} />
          <hr />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6 is-offset-1">
          <PageContent className="content " content={content} />
        </div>
        <div className="column is-3 is-offset-1">
          <Img
            className="full-width-image"
            alt="Psykolog Jens Henrik Thomsen"
            fluid={image.fluid}
          />
        </div>
      </div>
    </>
  );
};

function ProfilePage({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <ProfilePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={data.file.childImageSharp}
      />
    </Layout>
  );
}

export default ProfilePage;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
    file(relativePath: { eq: "profil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
